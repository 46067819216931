// @ts-nocheck
export const CoreDtOsQuestionType = {
  Interval: 'Interval',
  Single: 'Single',
  Multiple: 'Multiple',
  Text: 'Text',
  TrueFalse: 'TrueFalse',
  Select: 'Select',
  Nps: 'Nps',
  Html: 'Html',
  Language: 'Language',
  Numeric: 'Numeric',
  Date: 'Date',
  Reportdate: 'Reportdate',
  ContactInfo: 'ContactInfo',
  IntervalImportance: 'IntervalImportance',
  ContactInfoConsent: 'ContactInfoConsent',
  PrizeConsent: 'PrizeConsent',
  VolunteerConsent: 'VolunteerConsent',
  Volunteer: 'Volunteer',
  SponsorConsent: 'SponsorConsent',
  SOWAdvancedList: 'SOWAdvancedList',
  MatrixHeader: 'MatrixHeader',
} as const
export type CoreDtOsQuestionType =
  (typeof CoreDtOsQuestionType)[keyof typeof CoreDtOsQuestionType]
